import "../styles/App.css";
import "../assets/fonts/ink-free.ttf";

import GoTop from "../components/GoTop";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import OnePage from "../pages/OnePage";

import CookieConsent from "./CookieConsent.js";

import LogoLeco from "../assets/icones/logo_leco_PNG.png";
import FondDecoupe from "../assets/fonds/fond_decoupe.jpg";

import { Container } from "reactstrap";
import { useState, useEffect } from "react";
import Solution from "../pages/Solution.js";
import Contact from "../pages/Contact.js";
import QuiSommesNous from "../pages/QuiSommesNous.js";
import Connexion from "../pages/Connexion.js";
import Tarif from "../pages/Tarif.js";
import MergePages from "../pages/MergePages.js";

function App() {
  const [showGoTop, setshowGoTop] = useState(false);

  const [showMentions, setShowMentions] = useState(false);
  const [deployed, setDeployed] = useState(false);

  const { innerWidth: winWidth, innerHeight: winHeight } = window;
  var otherWinHeight = winWidth < 768 ? winHeight * 0.7 : winHeight;

  //SCROLL UP HANDLER
  const handleScrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  //SCROLL LISTENER
  useEffect(() => {
    //DISPLAY HANDLER
    const handleVisibleButton = () => {
      window.pageYOffset >= winHeight
        ? setshowGoTop(true)
        : setshowGoTop(false);
      setDeployed(false);
    };

    window.addEventListener("scroll", handleVisibleButton);
    return () => {
      window.removeEventListener("scroll", handleVisibleButton);
    };
  }, [winHeight]);

  useEffect(() => {
    //DISPLAY HANDLER
    const handleClickOutside = () => {
      if (
        document.getElementById("menuNavBar") &&
        deployed &&
        document.activeElement.className !== "navbar-toggler"
      ) {
        setDeployed(false);
      }
    };
    // Bind the event listener
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [deployed]);

  const menuList = [
    {
      id: "La solution",
      href: "solution",
      page: <Solution />,
    },
    { id: "Connexion", href: "connexion", page: <Connexion /> },
    {
      id: "Tarif",
      href: "tarif",
      page: (
        <MergePages
          showMentions={showMentions}
          setShowMentions={setShowMentions}
        />
      ),
      customStyle: {
        background: `url(${FondDecoupe})`,
        backgroundSize: "cover",
        backgroundPosition: "left top",
      },
    },
    { id: "Qui sommes-nous ?", href: "quisommesnous", page: null },
    { id: "Contact", href: "contact", page: null },
  ];

  return (
    <Container className="App">
      <GoTop showGoTop={showGoTop} scrollUp={handleScrollUp} />
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <OnePage
                menuList={menuList}
                deployed={deployed}
                setDeployed={setDeployed}
                showMentions={showMentions}
                setShowMentions={setShowMentions}
                winHeight={winHeight}
                otherWinHeight={otherWinHeight}
              />
            }
          />
          <Route
            exact
            path="/PrivacyPolicy"
            element={
              <PrivacyPolicy
                deployed={deployed}
                setDeployed={setDeployed}
                winHeight={winHeight}
              />
            }
          />
        </Routes>
      </Router>

      <CookieConsent
        message="Dans le but de vous accueillir au mieux, notre site utilise des cookies. En poursuivant votre navigation, vous acceptez leurs utilisations."
        buttonLabel="Accepter"
        logoSrc={LogoLeco}
        showMentions={showMentions}
        setShowMentions={setShowMentions}
      />
    </Container>
  );
}

export default App;
